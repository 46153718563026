import '../css/Projetos.css';

function Projetos(){
    return (
        <div className='projetos' id='projetos'>
            <h3>Projetos</h3>
            <div>
                <p>Agroecologia App</p>
                <p> Aplicativo Android como parte mobile da primeira fase da Plataforma Agroecológica de Garanhuns, 
                    projeto social que tem a finalidade de substituir o “Caderno de Campo - Plano de 
                    Manejo da Propriedade Familiar e Agroecológica” que era utilizado para registrar 
                    de forma precisa todas as observações pertinentes a produção, bem como os 
                    resultados parciais e finais obtidos, obtendo a certificação da produção.
                </p>
                <p>Links:</p>
                <p><a href='http://lmts.uag.ufrpe.br/br/content/plataforma-agroecol%C3%B3gica-de-garanhuns'>Laboratório Multidisciplinar de Tecnologias Sociais</a></p>
                <p><a href='https://github.com/glaa/sistema-de-gestao-agricola-app'>Repositório do projeto</a></p>
            </div>
            <div>
                <p>Gerador de Qr Code</p>
                <p> Site para gera qr code gratuito a partir de links, texto, PIX, whatsapp. Crie, use e compatilhe 
                    as imagens como preferir.
                </p>
                <p>Links:</p>
                <p><a href='https://geradorqrcode.gleison.dev.br'>Gerador de Qr Code</a></p>
                <p><a href='https://https://github.com/glaa/geradorqrcode'>Repositório do projeto</a></p>
            </div>
        </div>
    );
}

export default Projetos;